@import "./assets/fonts/SFProDisplay/SFProDisplay.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #f8f8f8;
}

h1,
h2 {
  font-family: "SF Pro Display Bold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
h3,
h4 {
  font-family: "SF Pro Display Semibold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

p {
  font-size: 14px;
  line-height: 20px;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.sectionTitle {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding: 15px 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.05); */
}
.sectionBody {
  padding: 15px 15px 20px;
}
.sectionLabel {
  margin-bottom: 6px;
}
.sectionBlock {
  margin-bottom: 15px;
}
.sectionBlockInline {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.sectionBlockInline .sectionLabel {
  width: 85px;
  margin-bottom: 0;
}
.sectionBlockInline .sectionContent {
  flex: 1;
}

.colorButton {
  /* background: #2a2c33; */
  display: flex;
  /* border-radius: 4px; */
  /* overflow: hidden; */
  flex-direction: row;
  align-items: center;
}

/* .colorButton:hover {
  background-color: #353640;
} */

.textInput {
  background: #2a2c33;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.6);
  border: 0;
  padding: 8px;
  height: 35px;
  flex: 1;
  font-size: 14px;
  width: 100%;
  position: relative;
  border: 2px solid transparent;
}
.textInput.textArea {
  height: 80px;
  resize: vertical;
  font-size: 15px;
  font-family: inherit;
  line-height: 1.4;
  overflow-y: scroll;
  overflow-x: hidden;
  color: rgba(255, 255, 255, 0.8);
}

.textInput:focus {
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.2);
  outline: none;
}
.textInputUnit {
  position: relative;
}
.textInputUnit .textInput {
  padding-right: 24px;
}
.inputScale::after {
  content: "%";
  position: absolute;
  top: 9px;
  right: 8px;
  font-size: 12px;
}
.inputPixel::after {
  content: "px";
  position: absolute;
  top: 9px;
  right: 8px;
  font-size: 12px;
}
.inputDegree::after {
  content: "°";
  position: absolute;
  top: 9px;
  right: 8px;
  font-size: 12px;
}

.textInputLabel {
  position: relative;
}
.textInputLabel::before {
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 13px;
  z-index: 2;
}

.textInputLabel .textInput {
  padding-left: 26px;
}

.inputLabelWidth::before {
  content: "W";
}
.inputLabelHeight::before {
  content: "H";
}
.inputLabelX::before {
  content: "X";
}
.inputLabelY::before {
  content: "Y";
}

.selectWrap {
  position: relative;
}
.selectWrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: auto;
  padding-right: 28px;
}
.selectWrap::after {
  content: "▼";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  /* background-color: red; */
}

.deviceButton {
  background: #2a2c33;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  /* padding-left: 2px; */
  cursor: pointer;
  overflow: hidden;
  transition: 0.12s linear;
}
.deviceButton:hover {
  background: #353640;
}
.deviceButton img {
  width: 70px;
  /* margin-top: 4px; */
  pointer-events: none;
  height: 60px;
  display: block;
}
.deviceButton .icon {
  width: 70px;
  height: 80px;
  display: flex;
  color: #fff;
  align-items: center;
}
.deviceButton .icon svg {
  transform: scale(0.5);
}
.deviceButton .details {
  padding: 0 10px 0 12px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deviceButton .details p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deviceButton .details > span {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

.buttonGroup .button {
  flex: 1;
  border: 0;
  text-align: center;
  padding: 8px 9.5px;
  background: #2a2c33;
  margin: 0 0.5px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.12s linear;
  border: 2px solid transparent;
}
.buttonGroup .button:focus {
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.buttonGroup .button.button-icon {
  padding: 0px 10px;
}
.buttonGroup .button:hover {
  background: #353640;
}
.buttonGroup .button.active {
  background: #0094dd;
  color: rgba(255, 255, 255, 1);
}
.buttonGroup .button:first-child {
  border-radius: 4px 0 0 4px;
}
.buttonGroup .button:last-child {
  border-radius: 0 4px 4px 0;
}
.buttonGroup .button:only-child {
  border-radius: 4px;
}
.buttonGroup .button img {
  height: 16px;
  width: 16px;
  display: block;
  font-size: 0;
}
.buttonGroup.buttonGroup-small .button {
  padding: 5px 14px 6px;
  font-size: 14px;
}

.buttonGroup.buttonGroup-small .button.button-icon {
  padding-right: 10px;
}

.buttonGroup.buttonGroupColors .button {
  flex: 0;
  background: transparent;
  padding: 5px;
  margin-right: 0px;
}

.browserView {
  position: relative;
  /* border-radius: 8px; */
  overflow: hidden;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3); */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.browser-chrome {
  background-image: url("./assets/browsers/chrome-start.png"),
    url("./assets/browsers/chrome-end.png"),
    url("./assets/browsers/chrome-mid.png");
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: left top, right top, center top;
  background-size: 365px 79px, 89px 79px, 10px 79px;
  height: 79px;
  width: 100%;
  /* position: absolute; */
  top: 0;
}

.browser-safari {
  background-image: url("./assets/browsers/safari-start.png"),
    url("./assets/browsers/safari-end.png"),
    url("./assets/browsers/safari-mid.png");
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: left top, right top, center top;
  background-size: 160px 42px, 94px 42px, 10px 42px;
  height: 42px;
}
.browser-simple {
  background-image: url("./assets/browsers/simple-start.png"),
    url("./assets/browsers/simple-mid.png");
  background-repeat: no-repeat, repeat-x;
  background-position: left top, center top;
  background-size: 80px 33px, 10px 33px;
  height: 33px;
}
.browser-arc {  
  background-repeat: no-repeat, repeat-x;
  background-position: left top, center top;
  background-size: 80px 33px, 10px 33px;
  height: 0;

}
.browser-empty {
  height: 0;
}

.browserContent {
  /* padding-top: 79px; */
  background-color: #fff;
}

.arc .browserView::after{
  position: absolute;
  content:"";
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius: 8px;
  border:5px solid #000;
}

/**
* Rangeslider
*/
.rangeslider {
  margin: 10px 0;
  position: relative;
  background: #2a2c33;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4); */
}
.rangeslider .rangeslider__handle {
  background: #fff;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.6);
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 2px;
  border-radius: 2px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #0094dd;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

#Mailchimp .formWrap {
  display: flex;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 15px;
}

#Mailchimp input {
  background: #f1f2f3;
  border: 0;
  flex: 1;
  font-size: 16px;
  height: 44px;
  padding: 10px 12px;
  border-radius: 4px;
}
#Mailchimp button {
  padding: 12px 20px;
  background: #0094dd;
  border: 0;
  border-radius: 0 4px 4px 0;
  color: white;
  font-size: 16px;
}

.logoWrap {
  display: flex;
  padding: 20px 15px 15px;
  align-items: center;
}
.logoWrap img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  pointer-events: none;
  margin-right: 8px;
}
.logoWrap a {
  display: block;
  font-size: 13px;
  line-height: 17px;
  color: #495e6c;
  text-decoration: none;
}
.logoWrap a:hover {
  text-decoration: underline;
}

.drawer-bottom {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0 0 15px;
  background: #fff;
}

.drawer .item {
  display: flex;
  flex: 1;
  padding: 5px 15px;
  color: #111;
  text-decoration: none;
  align-items: center;
}
.drawer .item:hover {
  background-color: #f7f7f7;
}

.drawer-bottom .itemGrid {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.drawer-bottom .itemGrid .item {
  flex: 1;
  display: flex;
  padding: 6px 8px;
  flex-direction: row;
  border-radius: 4px;
}
.drawer-bottom .itemGrid .item img {
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 0;
}
.drawer-bottom em {
  display: block;
  margin: 5px 10px;
  border-bottom: 1px solid #eee;
}
.drawer-bottom .item img {
  pointer-events: none;
  margin-right: 10px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
}
.drawer-bottom .item.ad {
  padding: 9px 12px;
}
.drawer-bottom .item.ad img {
  border-radius: 4px;
  width: 54px;
  height: 54px;
  margin-top: 2px;
}
.drawer-bottom .item p {
  flex: 1;
  font-size: 14px;
}
.drawer-bottom .item span {
  display: block;
  font-size: 13px;
  line-height: 17px;
  color: #495e6c;
}

#content {
  display: flex;
  width: 100%;
  overflow: hidden;
}

#root {
  flex: 1;
  overflow: auto;
}
#sidebar {
  /* width: 320px; */
  display: flex;
  flex-direction: column-reverse;
  background: #191a1e;
  height: calc(100vh - 68px);
}
#toast {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#toast > div {
  margin-bottom: 10px;
}

#watermark {
  position: absolute;
  left: 15px; 
  /* height: 30px; */
  /* display: flex; */
  padding: 8px 16px;
  align-items: center;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.8);
 color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  border-radius: 20px;
}

.fullLoading {
  background: #000;
  display: flex;
  color: #fff;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fullLoading p {
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  line-height: 25px;
}

/* EDITOR */

.deviceframe {
  position: relative;
  z-index: 3;
}
.deviceframe img {
  display: block;
  pointer-events: none;
}
.imageframe {
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: center;
}
.imageframe .imagewrap {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* box-shadow: 0 0 0 3px red; */
  border-radius: 23px;
  box-shadow: 0 0 0 2px #000;
  position: relative;
}
.imageframe .imagewrap.sharpCorner{
  border-radius: 0;
}

.imageframe img {
  width: 100%;
  /* height: 100%; */
  pointer-events: none;
  object-fit: cover;
  object-position: top;
}

.emptyimage {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #1d2129;
}
.emptyimage p {
  font-size: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.3;
  margin: 0 50px;
}

.editable {
  z-index: 2000;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  background: rgba(0, 0, 0, 0.2);
}
/* Drag resize rotate */

#canvas .gblcbk {
  border: none;
  z-index: 0;
}

#canvas .gblcbk .square {
  background-color: #fff;
  width: 11px;
  height: 11px;
  border: 2px solid red;
}

#canvas .gblcbk .tl,
#canvas .gblcbk .tr,
#canvas .gblcbk .t {
  top: -6px;
}
#canvas .gblcbk .tr,
#canvas .gblcbk .br,
#canvas .gblcbk .r {
  right: -6px;
}
#canvas .gblcbk .tl,
#canvas .gblcbk .bl,
#canvas .gblcbk .l {
  left: -6px;
}
#canvas .gblcbk .bl,
#canvas .gblcbk .br,
#canvas .gblcbk .b {
  bottom: -6px;
}
#canvas .gblcbk .t,
#canvas .gblcbk .b {
  margin-left: -5px;
}
#canvas .gblcbk .l,
#canvas .gblcbk .r {
  margin-top: -5px;
}
/* #canvas .chrome .gblcbk .bl,
#canvas .chrome .gblcbk .br {
  bottom: -86px;
} */
#canvas .gblcbk .rotate i {
  background-image: none;
  border: 2px solid red;
  background-color: #fff;
  border-radius: 50px;
  width: 14px;
  height: 14px;
}
#canvas .gblcbk .rotate {
  top: -40px;
  cursor: grab;
  /* position: relative; */
}
#canvas .gblcbk .rotate::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 2px;
  left: 6px;
  top: 13px;
  background: red;
  opacity: 0.6;
}


.twitter-card{ 
  /* padding: 30px 30px 30px; */
  color:#000;
  text-align: left; 
}

.twitter-card .tweet-top{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.twitter-card .tweet-user{
  display:flex;
  align-items: center;
}
.twitter-card .tweet-user img.avatar{
  margin-right: 12px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.twitter-card .tweet-user p.name{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}
.twitter-card .tweet-user p.name em{
  position: relative;
  top: 5px;

}

.twitter-card .tweet-user p.handle{
  font-size: 16px;
  color:rgba(0,0,0,0.6);
}

.twitter-card .tweet_text{ 
  white-space: pre-line;
  line-height: 1.35 ;
  letter-spacing: -0.2px;
  font-family: sans-serif;
  /* margin-bottom: 20px; */
}
.twitter-card .tweet_text p{
  margin-bottom: 10px; 
}


.twitter-card .tweet_media{
  display:grid;
  border-radius:8px;
  overflow: hidden;
  margin-top: 20px;
  gap: 2px; /* Optional: Add gap between grid items */
}

.twitter-card .tweet_media img{
  max-width: 100%;
  height: auto;
  display: inline-block;
  background-color: #eee;
}
.twitter-card .tweet_media.grid-2{
  grid-template-columns: 1fr 1fr;
}
.twitter-card .tweet_media.grid-3{
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;;
}
.twitter-card .tweet_media.grid-3 img:nth-child(1){
  grid-row: span 2;
  width: 100%;
  height:100%;
  object-fit: cover;
}
.twitter-card .tweet_media.grid-3 img:nth-child(2),
.twitter-card .tweet_media.grid-3 img:nth-child(3){
  grid-column: 2;
  height: 100%;
  width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.twitter-card .tweet_media.grid-4{
grid-template-columns: 1fr 1fr;
grid-template-rows: 1fr 1fr;
}


.twitter-card .tweet_media.grid-4 img{
  height: 200px;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
}
.twitter-card .tweet_media.grid-2 img{
  height: 300px;
  overflow: hidden;
  width: 100%;
  object-fit: cover;

}

.twitter-card .tweet-date {
  margin-top: 24px;
  /* margin-bottom: 20px; */
    font-size: 16px;
    color:#555;
}

.twitter-card .tweet-metrics {
  display: flex;
  margin-top:16px;
  padding-top: 16px;
  border-top:1px solid #eee; 
  color:#555;
}
.twitter-card .tweet-metrics.noTime {
border:0;
padding-top:12px;
}
.twitter-card .tweet-metrics p{
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;

}
.twitter-card .tweet-metrics p svg{
  margin-right: 4px;
}


 
.twitter-dark-mode{
  background-color: #000;
  color:rgba(255,255,255,0.9)
}
.twitter-dark-mode .tweet-user p.handle,
.twitter-dark-mode .tweet-metrics,
.twitter-dark-mode .tweet-date  {
  color:rgba(255,255,255,0.5)
}

.twitter-dark-mode .tweet-metrics{
  border-color: rgba(255,255,255,0.15);
}

.colorgrid{
  display: grid;
  grid-template-columns: repeat(6, 41px);
  grid-template-rows: repeat(3, 41px);
  gap: 4px;
 
}
.colorgrid button {
  width: 41px;
  height: 41px;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
}
