@font-face {
  font-family: "SF Pro Display Black";
  src: url("SF-Pro-Display-Black.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Bold";
  src: url("SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Heavy";
  src: url("SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Light";
  src: url("SF-Pro-Display-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Medium";
  src: url("SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Regular";
  src: url("SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Semibold";
  src: url("SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Thin";
  src: url("SF-Pro-Display-Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
